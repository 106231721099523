import React from "react";
import style from "../styles/Home.module.css";
import Carousel from "./Carousel";

function ReviewBlock() {
  return (
    <div className="container">
      <div className="col-12 bg-white my-3 my-md-5">
        <div className={`${style.featured} ${style.reviews}`}>
          <div className="" style={{ position: "relative" }}>
            <div className="d-flex align-items-lg-center align-items-md-center flex-column mb-lg-4 ">
              <h3 className="feature-title p-0 fw-bold mt-3 textUpperCase">
                Your Review Matters, and your support matters!
              </h3>
              <p className=" small m-0">
                <p
                  className=" m-0 feature-paragraph fw-400"
                  style={{ color: "#333333" }}
                >
                  Our clients from across the world have their experiences to
                  share with you, do read them!
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className={`${style.ReviewBlock} row`}>
          <div className="col-lg-1"></div>
          <div className="col-12 col-lg-5 mt-3">
            <div className="d-flex ">
              <div>
                <img
                  src="/images/customer.svg"
                  className="img-fluid pt-1"
                  alt=""
                />
              </div>
              <div className="ms-4">
                <h4 className="">Over 5000+ people booked their retreats</h4>
                <h6>with us in the last 6 months</h6>
              </div>
            </div>
            <div className="d-flex mt-4 mt-lg-4 mt-md-4">
              <div>
                <img
                  src="/images/booking.svg"
                  className="img-fluid pt-1"
                  alt=""
                />
              </div>
              <div className="ms-4">
                <h4 className="">97% of them are happy and satisfied</h4>
                <h6>with finding the most quality retreats</h6>
              </div>
            </div>
            <div className="d-flex mt-4 mt-lg-4  mt-md-4">
              <div>
                <img
                  src="/images/satisfaction.svg"
                  className="img-fluid pt-1"
                  alt=""
                />
              </div>
              <div className="ms-4">
                <h4 className="">4.9 is our average review rating</h4>
                <h6>& we have the most five-star recommendations in-hand</h6>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 mt-4 mt-lg-0">
            <Carousel />
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}

export default ReviewBlock;
