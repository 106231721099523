import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { styled } from "@mui/material";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { actions as actionsUpdate } from "../redux/Frontend/ListingsFilter/redux/slice";
import { RetreatsTypeEnum } from "../redux/Frontend/ListingsFilter/types";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import Image from "next/image";
function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviewDetails.length;
  const [autoPlaySwitch, setAutoPlaySwitch] = useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (label: any) => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  const handleStepChange = (step: any) => {
    setActiveStep(step);

    if (step == 4) {
      setAutoPlaySwitch(false);
    }
  };

  const handleChange = (index: number) => {
    setActiveStep(index);
  };

  const YearButton = styled(Button)({
    fontFamily: "Cochin",
    fontWeight: 700,
    fontsize: 25,
    color: "#00245B",
    "&:hover": {
      backgroundColor: "#F8F6F0",
      borderColor: "#F8F6F0",
      boxShadow: "none",
    },
  });

  const dispatch = useDispatch();
  const router = useRouter();
  const handleRoute = (retreatTypeFilter: any, activityFilter: any) => {
    let qr = { retreatTypeFilter, activityFilter };
    !qr.retreatTypeFilter && delete qr.retreatTypeFilter;
    !qr.activityFilter && delete qr.activityFilter;
    dispatch(
      actionsUpdate.updateListingsFilterFormValue({
        key: "retreatTypeFilter",
        value: parseInt(qr.retreatTypeFilter) || [],
      })
    );
    if (qr.activityFilter?.length > 0) {
      dispatch(actionsUpdate.setActivityList(qr.activityFilter));
    }

    router.push({
      pathname: "/listing",
      query: qr,
    });
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {/* <Box sx={{ flexGrow: 1, boxShadow: { xs: "0px 5px #ebe9e9,0px 10px #f2eded", sm: "5px 5px #ebe9e9,10px 10px #f2eded" } }}> */}
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          interval={3000}
          enableMouseEvents
        >
          {reviewDetails.map((item, index) => (
            <>
              <Box
                sx={{
                  display: { xs: "none", sm: "block" },
                  flexGrow: 1,
                  height: "100%",
                  borderRight: 1,
                  borderBottom: 1,
                  borderRadius: 2,
                  padding: "0 5px 5px 0",
                  borderRightColor: "#dcdcdc !important",
                  borderBottomColor: "#dcdcdc !important",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    borderRight: 1,
                    borderBottom: 1,
                    borderRadius: 2,
                    padding: "0 5px 5px 0",
                    borderRightColor: "#dcdcdc !important",
                    borderBottomColor: "#dcdcdc !important",
                  }}
                >
                  <div className="card h-100" key={index}>
                    <div className="card-body pb-0">
                      <div className="d-flex">
                        <div>
                          
                          <Image
                            src={item.reviewerImg}
                            alt="Card image cap"
                            width={65}
                            height={65}
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                            className="img-fluid card-img-top carouselImage"
                          />
                        </div>
                        <div className="ms-3 d-flex align-items-center">
                          <div className="d-block">
                            <h3 className="mb-0">{item.reviewer}</h3>
                            <div className="mt-0">
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 className="mt-3">&quot;{item.review}&quot;</h5>
                    </div>
                    <div className="card-footer bg-white border-0 d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-md-content-center ">
                        <p className="mb-0">{item.reviewDept}</p>
                      </div>
                      <div>
                        {item.reviewDept == "Yoga Retreat" && (
                          <button
                            className="btn btnHover px-3 px-lg-2 px-xl-3"
                            onClick={() => handleRoute(null, "Yoga Retreat")}
                          >
                            Yoga Retreats
                          </button>
                        )}
                        {item.reviewDept == "Online Retreat" && (
                          <button
                            className="btn btnHover px-3 px-lg-2 px-xl-3"
                            onClick={() =>
                              handleRoute(RetreatsTypeEnum.Online, null)
                            }
                          >
                            Online Retreats
                          </button>
                        )}
                        {item.reviewDept == "Yoga Teacher Training" && (
                          <button
                            className="btn btnHover px-3 px-lg-2 px-xl-3"
                            onClick={() =>
                              handleRoute(null, "Yoga Teacher Training")
                            }
                          >
                            Online Yoga Training
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", sm: "none" },
                  flexGrow: 1,
                  height: "100%",
                  borderBottom: 1,
                  borderRadius: 2,
                  padding: "0 5px 5px 0",
                  borderBottomColor: "#dcdcdc !important",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    borderBottom: 1,
                    borderRadius: 2,
                    height: "100%",
                    padding: "0 5px 5px 0",
                    borderBottomColor: "#dcdcdc !important",
                  }}
                >
                  <div className="card h-100" key={index}>
                    <div className="card-body pb-0">
                      <div className="d-flex">
                        <div>
                          {/* <img
                            className="card-img-top"
                            src={item.reviewerImg}
                            alt="Card image cap"
                            style={{ maxWidth: "65px" }}
                          /> */}
                          <Image
                            src={item.reviewerImg}
                            alt="Card image cap"
                            width={65}
                            height={65}
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                            className="img-fluid card-img-top carouselImage"
                          />
                        </div>
                        <div className="ms-3 d-flex align-items-center">
                          <div className="d-block">
                            <h3 className="mb-0">{item.reviewer}</h3>
                            <div className="mt-0">
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star ms-0 mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                              <FontAwesomeIcon
                                icon="star"
                                width="14"
                                className="star mt-0"
                                style={{ color: "#f7ca18" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 className="mt-3">&quot;{item.review}&quot;</h5>
                    </div>
                    <div className="card-footer bg-white border-0 d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-md-content-center ">
                        <p className="mb-0">{item.reviewDept}</p>
                      </div>
                      <div>
                        {item.reviewDept == "Yoga Retreat" && (
                          <button
                            className="btn btnHover px-3 px-lg-2 px-xl-3"
                            onClick={() => handleRoute(null, "Yoga Retreat")}
                          >
                            Yoga Retreats
                          </button>
                        )}
                        {item.reviewDept == "Online Retreat" && (
                          <button
                            className="btn btnHover px-3 px-lg-2 px-xl-3"
                            onClick={() =>
                              handleRoute(RetreatsTypeEnum.Online, null)
                            }
                          >
                            Online Retreats
                          </button>
                        )}
                        {item.reviewDept == "Yoga Teacher Training" && (
                          <button
                            className="btn btnHover px-3 px-lg-2 px-xl-3"
                            onClick={() =>
                              handleRoute(null, "Yoga Teacher Training")
                            }
                          >
                            Online Yoga Training
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            </>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
    </>
  );
}

export default SwipeableTextMobileStepper;

const reviewDetails = [
  {
    reviewerImg: "/images/o0rvpok6fzrchfl1.png",
    reviewer: "Connie Li",
    review:
      "I booked my Yoga wellness retreat with Book Your Retreats and I absolutely loved it! The program was very well organized and I felt like I was able to learn a lot about myself and my body.",
    reviewDept: "Yoga Retreat",
  },
  {
    reviewerImg: "/images/Bookingname_img7.png",
    reviewer: "Katherine Lee",
    review:
      "I wasn't sure what to expect from an online retreat, but this one was fantastic! I loved the yoga classes and the healthy eating tips. I felt so relaxed and refreshed after.",
    reviewDept: "Online Retreat",
  },
  {
    reviewerImg: "/images/girl-update-review.jpg",
    reviewer: "Lilou Vidal ",
    review:
      "I am so grateful that I found your yoga teacher training program! The content was rich and comprehensive, and I loved being able to study at my own pace.",
    reviewDept: "Yoga Teacher Training",
  },
];
